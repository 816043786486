.error {
  color: #000;
  text-align: center;
  animation: fadein 1.2s ease-in;
}
.error  .code {
  font-size: 10.5em;
  text-shadow:  0 6px 1px rgba(0,0,0,0.0980392) , 0 0 5px rgba(0,0,0,0.0980392) , 0 1px 3px rgba(0,0,0,0.298039) , 0 3px 5px rgba(0,0,0,0.2) , 0 5px 10px rgba(0,0,0,0.247059) , 0 10px 10px rgba(0,0,0,0.2) , 0 20px 20px rgba(0,0,0,0.14902) ;
  margin: 0;
  line-height: 1.5;
}

.error  .code.server {
  font-size: 6em;
} 

.error  .desc {
  color: #000;
  font-size: 2.4em;
  font-weight: 400;
  line-height: 2;
}
@keyframes fadein {
  0% {
    margin-top: -50px;
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .error  .code {
    font-size: 5em;
  }
   .error  .code.server {
    font-size: 3em;
  }
  .error > .desc {
    font-size: 1.4em;
  }
}